import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import { pageResolver } from "../utils/linkResolver"

interface Props {
  pageContext: PageContext
}

export interface PageContext {
  limit: number
  numPages: number
  currentPage: number
}

const isFirstPage = (pageContext: PageContext) => {
  return pageContext.currentPage === 1
}

const isLastPage = (pageContext: PageContext) => {
  return pageContext.currentPage === pageContext.numPages
}

const addSplitWhereNeeded = (temp: number[]) => {
  const result = []
  for (let i = 0; i < temp.length; i++) {
    const current = temp[i]
    result.push(current)
    if (i + 1 < temp.length) {
      const next = temp[i + 1]
      if (current + 1 !== next) {
        result.push(-1)
      }
    }
  }
  return result
}

const startingFromFirst = (maxSiblings: number, pageContext: PageContext) => {
  const temp = []
  for (let i = 1; i <= maxSiblings; i++) {
    if (i < pageContext.numPages) {
      temp.push(i)
    }
  }

  return addSplitWhereNeeded([...temp, pageContext.numPages])
}

const startingFromLast = (maxSiblings: number, pageContext: PageContext) => {
  const temp = []
  for (
    let i = pageContext.numPages;
    i > pageContext.numPages - maxSiblings;
    i--
  ) {
    if (i > 1) {
      temp.push(i)
    }
  }
  temp.reverse()

  return addSplitWhereNeeded([1, ...temp])
}

const startingFromMiddle = (maxSiblings: number, pageContext: PageContext) => {
  const sideItems = (maxSiblings - 1) / 2
  const itemsBefore = []
  for (
    let i = pageContext.currentPage - sideItems;
    i < pageContext.currentPage;
    i++
  ) {
    if (i > 1) {
      itemsBefore.push(i)
    }
  }

  const itemsAfter = []
  for (
    let i = pageContext.currentPage + 1;
    i <= pageContext.currentPage + sideItems;
    i++
  ) {
    if (i < pageContext.numPages) {
      itemsAfter.push(i)
    }
  }

  const temp = [
    1,
    ...itemsBefore,
    pageContext.currentPage,
    ...itemsAfter,
    pageContext.numPages,
  ]

  return addSplitWhereNeeded(temp)
}

const calculateSiblings = (siblings: number) => {
  const defaultSiblings = 3
  if (siblings < defaultSiblings) {
    return defaultSiblings
  } else if (siblings % 2 == 0) {
    return siblings + 1
  } else {
    return siblings
  }
}

/**
 * @param maxSiblings, must be an odd number and greater or equal to three
 * @param pageContext
 */
const renameMe = (maxSiblings: number, pageContext: PageContext): number[] => {
  const finalSiblings = calculateSiblings(maxSiblings)
  if (isFirstPage(pageContext)) {
    return startingFromFirst(finalSiblings, pageContext)
  } else if (isLastPage(pageContext)) {
    return startingFromLast(finalSiblings, pageContext)
  } else {
    return startingFromMiddle(finalSiblings, pageContext)
  }
}

const PageNavigation: React.FC<Props> = ({ pageContext }) => {
  const shownPages = renameMe(3, pageContext)

  const items = []
  for (let i = 0; i < shownPages.length; i++) {
    const pageNumber = shownPages[i]
    const isSplit = pageNumber === -1

    if (isSplit) {
      items.push(
        <li
          className={
            "mx-1 px-3 py-2 text-gray-700 rounded-lg cursor-not-allowed"
          }
          key={`page-${pageNumber}`}
        >
          <span className="font-bold">...</span>
        </li>
      )
    } else {
      items.push(
        <li
          className={
            "mx-1 bg-gray-200 hover:bg-gray-600 hover:text-gray-200 rounded-lg cursor-pointer " +
            (pageNumber === pageContext.currentPage
              ? "shadow shadow-inner bg-gray-600 text-white"
              : "text-gray-600")
          }
          key={`page-${pageNumber}`}
        >
          <Link className="block px-3 py-2" to={pageResolver(pageNumber)}>
            <span className="font-bold">{pageNumber}</span>
          </Link>
        </li>
      )
    }
  }

  return (
    <ul className="flex justify-center">
      <li
        className={
          (pageContext.currentPage > 1 ? "md:visible" : "") +
          " invisible bg-gray-200 text-gray-700 hover:bg-gray-700 hover:text-gray-200 rounded-lg cursor-pointer mr-8"
        }
      >
        <Link
          className="block px-3 py-2"
          to={pageResolver(pageContext.currentPage - 1)}
          aria-label="Previous"
        >
          <div className="flex items-center font-bold">
            <span className="mx-1">
              <FontAwesomeIcon icon="arrow-left" />
            </span>
          </div>
        </Link>
      </li>
      {items}
      <li
        className={
          (pageContext.currentPage < pageContext.numPages ? "md:visible" : "") +
          " invisible bg-gray-200 text-gray-700 hover:bg-gray-700 hover:text-gray-200 rounded-lg cursor-pointer ml-8"
        }
      >
        <Link
          className="block px-3 py-2"
          to={pageResolver(pageContext.currentPage + 1)}
          aria-label="Next"
        >
          <div className="flex items-center font-bold">
            <span className="mx-1">
              <FontAwesomeIcon icon="arrow-right" />
            </span>
          </div>
        </Link>
      </li>
    </ul>
  )
}

export default PageNavigation
