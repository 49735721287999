import React from "react"
import { BlogListQuery, Prismic_Post } from "../../graphql-types"
import BlogPostSummary from "./BlogPostSummaryComponent"

interface BlogPostProps {
  posts: BlogListQuery["prismic"]["allPosts"]["edges"]
}

const BlogPosts: React.FC<BlogPostProps> = ({ posts }) => {
  if (!posts) {
    return null
  }

  return (
    <div className="flex flex-wrap -mx-2 overflow-hidden">
      {posts.map(post => {
        if (!post) return null
        return (
          <div key={post.node._meta.id} className="my-2 px-2 w-full sm:w-1/2">
            <BlogPostSummary post={post.node as Prismic_Post} />
          </div>
        )
      })}
    </div>
  )
}

export default BlogPosts
