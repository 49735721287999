import React from "react"
import Img from "gatsby-image"

interface Props {
  image: any
  alt: string
  fallback: string
}

const FluidImage: React.FC<Props> = ({ image, alt, fallback }) => {
  return (
    <div>
      {image && image.childImageSharp && image.childImageSharp.fluid ? (
        <Img alt={alt} fluid={image.childImageSharp.fluid} />
      ) : (
        <img src={fallback} alt={alt} />
      )}
    </div>
  )
}

export default FluidImage
