import React from "react"
import { Link } from "gatsby"
import { documentResolver } from "../utils/linkResolver"
import { Prismic_Post } from "../../graphql-types"
// @ts-ignore
import { RichText } from "prismic-reactjs"
import FluidImage from "./FluidImageComponent"

interface Props {
  post: Prismic_Post
}

const BlogPostSummary: React.FC<Props> = ({ post }) => {
  return (
    <div className="h-full flex flex-col rounded overflow-hidden shadow-lg bg-white">
      <Link to={documentResolver(post._meta)}>
        <div className="w-full cursor-pointer">
          {
            <FluidImage
              image={post.imageSharp}
              alt={post.image.alt}
              fallback={post.image.url}
            />
          }
        </div>
      </Link>
      <div className="px-6 py-6 flex-1 flex flex-col">
        <div className="flex-1">
          <div className="mb-2 text-gray-700 text-sm">
            <div>{post.date}</div>
          </div>
          <Link to={documentResolver(post._meta)}>
            <div className="font-bold text-lg mb-2 cursor-pointer">
              {post.title && RichText.asText(post.title)}
            </div>
          </Link>
          <p className="text-gray-700 text-base mb-8">
            {post.excerpt && RichText.asText(post.excerpt)}
          </p>
        </div>
        <div className="text-gray-700 text-sm self-end flex flex-wrap justify-end -m-1">
          {post._meta.tags &&
            post._meta.tags.map((tag, index) => (
              <span
                key={`tag-${index}`}
                className="shadow-inner bg-gray-200 rounded-lg px-3 py-2 m-1"
              >
                #{tag}
              </span>
            ))}
        </div>
      </div>
    </div>
  )
}

export default BlogPostSummary
