import React from "react"
import { graphql } from "gatsby"
import { BlogListQuery } from "../../graphql-types"
import LayoutComponent from "../components/layout/LayoutComponent"
import BlogPosts from "../components/BlogPostsComponent"
import PageNavigation, {
  PageContext,
} from "../components/PageNavigationComponent"
import SEO from "../components/seo/SeoComponent"

interface Props {
  data: BlogListQuery
  pageContext: PageContext
}

const BlogList: React.FC<Props> = ({ data, pageContext }: Props) => {
  const posts = data.prismic.allPosts.edges

  return (
    <LayoutComponent>
      <SEO />
      <div className="mb-5">
        <BlogPosts posts={posts} />
      </div>
      {pageContext.numPages > 1 && (
        <div className="mb-3">
          <PageNavigation pageContext={pageContext} />
        </div>
      )}
    </LayoutComponent>
  )
}

export default BlogList

export const query = graphql`
  query BlogList($limit: Int!, $cursor: String) {
    prismic {
      allPosts(sortBy: date_DESC, first: $limit, after: $cursor) {
        edges {
          node {
            _meta {
              id
              uid
              type
              tags
            }
            title
            date
            image
            imageSharp {
              childImageSharp {
                fluid(maxWidth: 500, maxHeight: 300, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            excerpt
          }
        }
      }
    }
  }
`
